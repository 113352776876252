<template>
  <div class="csn-prom-view">
    <img
      src="@/assets/images/welcome_bonus.png"
      class="csn-prom-view-image-one"
    />
    <img
      src="@/assets/images/offers/offers-1.png"
      class="csn-prom-view-image-one-mobile"
    />
    <div class="csn-prom-view-text-container">
      <div class="csn-prom-view-section-one">
        <p>{{ t('text_1') }}</p>
        <p>{{ t('text_2') }}</p>
        <p class="csn-prom-view-list-item csn-prom-view-section-one-par">
          <PlatinCoinIcon />
          <span>{{ t('text_3') }}</span>
        </p>
        <p class="csn-prom-view-list-item csn-prom-view-section-one-par">
          <PlatinCoinIcon />
          <span>{{ t('text_4') }}</span>
        </p>
        <p class="csn-prom-view-list-item csn-prom-view-list-item-last">
          <PlatinCoinIcon />
          <span>{{ t('text_5') }}</span>
        </p>
        <p class="csn-prom-view-section-one-par">{{ t('text_6') }}</p>
        <p class="csn-prom-view-section-one-par">{{ t('text_7') }}</p>
        <img
          src="@/assets/images/select-deposit-example.png"
          alt="select deposit example"
        />
      </div>
      <div class="csn-prom-view-section-two">
        <p class="csn-prom-view-section-two-par-one">{{ t('text_8') }}</p>
        <span class="csn-prom-view-section-two-header">
          {{ t('show_results') }}
        </span>
        <table class="csn-prom-view-section-two-table">
          <tr>
            <td>{{ t('game') }}</td>
            <td>{{ t('weighted') }}</td>
          </tr>

          <tr>
            <td>{{ t('slots') }}</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>{{ t('other_games') }}</td>
            <td>75%</td>
          </tr>
          <tr>
            <td>{{ t('rouletter') }}</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>{{ t('sic_bo') }}</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>{{ t('blackjack') }}</td>
            <td>10%</td>
          </tr>
          <tr>
            <td>{{ t('stud_poker') }}</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>{{ t('rosk_ladder') }}</td>
            <td>0%</td>
          </tr>
        </table>
        <div class="csn-prom-view-section-two-text">
          <p>{{ t('text_9') }}</p>
          <p>{{ t('text_10') }}</p>
        </div>
      </div>
      <div class="csn-prom-view-section-three">
        <span class="csn-prom-view-section-three-title">
          {{ t('text_11') }}
        </span>
        <span class="csn-prom-view-section-three-text">
          {{ t('text_12') }}
        </span>
        <span class="csn-prom-view-section-three-title">
          {{ t('text_13') }}
        </span>
        <span class="csn-prom-view-section-three-text">
          {{ t('text_14') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { PROMOTION_VIEW } from '@/constants'
export default {
  name: PROMOTION_VIEW,
  components: {
    PlatinCoinIcon: () => import('@/components/svg/PlatinCoinIcon'),
  },
  computed: {
    t() {
      return this.$createComponentTranslator(PROMOTION_VIEW)
    },
  },
}
</script>
